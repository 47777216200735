@font-face {
  font-family: 'VySans';
  src: local('MySans-Regular'),
    url(../shared/fonts/VySans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'VySans-Italic';
  src: local('VySans-Italic'),
    url(../shared/fonts/VySans-RegularItalic.woff) format('woff');
}

@font-face {
  font-family: 'VySans-Bold';
  src: local('VySans-Bold'),
    url(../shared/fonts/VySans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'VySans-BoldItalic';
  src: local('VySans-BoldItalic'),
    url(../shared/fonts/VySans-BoldItalic.woff) format('woff');
}

@font-face {
  font-family: 'VySans-Light';
  src: local('VySans-Light'),
    url(../shared/fonts/VySans-Light.woff) format('woff');
}

@font-face {
  font-family: 'VySans-LightItalic';
  src: local('VySans-LightItalic'),
    url(../shared/fonts/VySans-LightItalic.woff) format('woff');
}

@font-face {
  font-family: 'VyDisplay-Medium';
  src: local('VyDisplay-Medium'),
    url(../shared/fonts/VyDisplay-Medium.woff) format('woff');
}
